import '/third_party/vicowa-web-components/dist/vicowa-translate/vicowa-translate.js';
import '/third_party/vicowa-web-components/dist/vicowa-button/vicowa-button.js';
import '/third_party/vicowa-web-components/dist/vicowa-content-container/vicowa-content-container.js';
import '/webcomponents/vicowa-styles/vicowa-styles.js';
import '/webcomponents/vicowa-web-layout/vicowa-web-layout.js';
import features from '/third_party/vicowa-web-components/dist/utilities/featureDetect.js';
import { createQuickAccess } from '/third_party/web-component-base-class/dist/tools.js';
import { getRouter } from '/third_party/vicowa-web-components/dist/utilities/route.js';

const router = getRouter(window);
const controls = createQuickAccess(document, 'id');

if (features.webp) {
	document.body.classList.add('webp');
}

Array.from(document.querySelectorAll('.nav-button')).forEach((p_Element) => {
	p_Element.onclick = (event) => {
		router.goTo(p_Element.href, { title: p_Element.dataset.title, href: p_Element.href });
		event.preventDefault();
	};
});

router.addRoute('/:file(.*)', (context, next) => {
	const parts = context.params.file.split('#');
	controls.content.location = `./${parts[0] || 'vicowa-introduction'}.js`;
	if (context.customData) {
		document.title = `Rodney - ${context.customData.title}`;
	}
	next();
}, (context) => {
	Array.from(document.querySelectorAll('.nav-button')).forEach((control) => {
		control.classList.toggle('active', context.customData && control.href === context.customData.href);
	});
});
